import '../css/app.less';
require('bootstrap')
const d3 = require('d3');
const $ = require('jquery');
global.$ = global.jQuery = $;
const _ = require('lodash');
global._ = _;

let sessionTimeLeft = -1;

// 30 mins
window.resetSessionTimeout = function() {
    sessionTimeLeft = 60 * 30;
}

window.resetSessionTimeout();
if(window.location.href.indexOf('sessionModalTest') > -1) {
    sessionTimeLeft = 180;
}

window.sbCheck = function() {
    jQuery('#sbCheckAlert').modal({
        show: true,
        keyboard: false,
        backdrop: 'static'
    });
};

window.sbLoading = function() {
    $(".loading-backdrop").removeClass("hidden");
    $("#sb-loader").addClass('sb-visible');
    $("#sb-loader-hide").addClass('sb-visible-hide');
};


$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('body').on('click', 'a', function(){
        if (typeof $(this).attr('href') != 'undefined'){
            const hrefVal = $(this).attr('href');
            if(!hrefVal.startsWith("#")  && !$(this).attr('target')) {
                sbLoading();
            }
        }

    });
});

$(document).on("click", "#closeLoader", function(){
    $(".loading-backdrop").addClass("hidden");
    $("#sb-loader").removeClass('sb-visible');
    $("#sb-loader-hide").removeClass('sb-visible-hide');
    return false;
});

$.ajaxSetup({
    complete: function() {
        window.resetSessionTimeout();
    },
});

const timer = setInterval(() => {
    sessionTimeLeft -= 1;
    let mins = Math.floor(sessionTimeLeft / 60);
    let sec = sessionTimeLeft - (60 * mins);

    let label = '';
    if(mins > 0){
        label = mins + ':' + sec + ' min';
    }else{
        label = sec + ' sec';
    }

    $("#sessionTime").html(label);

    if(sessionTimeLeft === 120) {
        $('#sessionExpiringModal').modal('show');
    }else if(sessionTimeLeft === 0) {
        clearInterval(timer);
        $("#sessionExpiringModal .alert").html("Your session has expired. Please reload your browser to continue.");
        $("#keepWorking").hide();

    }
}, 1000);

$('#sessionExpiringModal').modal({
    keyboard: false,
    backdrop: 'static',
    show: false,
});

$("#keepWorking").click(function(){
    $.get(Routing.generate("user_refresh_session"))
.then(function() {
        window.resetSessionTimeout();
    });
});